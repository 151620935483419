import { useTokenDecoder } from '~/composables/useTokenDecoder'
import { useRouteStore } from '~/stores/route.js'

export default defineNuxtRouteMiddleware(async (to) => {
  let token = to.params?.token
  const data = useTokenDecoder(token)
  if (!data) return navigateTo('/')
  token = data.token || token
  useRouteStore().setToken(token, data)
})
